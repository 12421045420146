import { AbstractForm } from "../../abstract/AbstractForm";
import { EmailTypes } from "../../types/EmailTypes";

export class JobApplyFormComponent extends AbstractForm {
  constructor() {
    super(document.querySelector('#job-apply-form'), EmailTypes.JobApplication);
  }

  afterSuccessfulSend() {
    const formFileInput = document.querySelector('#job-apply-form file-input');

    if (formFileInput) {
      document.querySelector('#job-apply-form file-input').onFileDelete();
    }
  }
}
